/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { Box, Divider, MobileStepper, Typography } from '@mui/material';
import { useReactiveVar, useQuery, useMutation } from '@apollo/client';

import GET_CONFIG_PLANS from '../../../graphql/querys/plan/getConfigPlans';
import { getDriverStore, setVerifiedList, getAuthStore } from '../../../graphql/store';
import LoadingProgress from '../../../components/ui/LoadingProgress';

import CREATE_DRIVER_PLAN from '../../../graphql/mutations/plan/createDriverPlan';
import EDIT_DRIVER_PLAN from '../../../graphql/mutations/plan/editDriverPlan';

import ErrorView from '../../general/ErrorView';
import VideoModal from '../../../components/extras/VideoModal';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

import SelectContentPlan from './auxComponents/selectContentPlan';
import PlanItemFunction from './PlanItemFunction';
import CategoryIcon from '../../../components/ui/categoryIcon';
import { azulTucar } from '../../../themes/variants/TucarColors';
import NewSubscriptionPlan from '../../app/subscription/NewVersion';
import OldSubscriptionPlan from '../../app/subscription/OldVersion';
import SubmitButton from '../../../components/SubmitButton';
import handleOnBoarding from '../../../config/mixPanel/handleOnBoarding';

function planOrderFunction(a, b) {
  if (a.props.plan.electric && !b.props.plan.electric) {
    return -1;
  }
  if (a.props.plan.shifts.length > 1 && b.props.plan.shifts.length === 1) {
    return 1;
  }
  return 0;
}

const OnBoardingPlan = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const {
    labelSubscription,
    verifiedList,
    info: { country },
  } = useReactiveVar(getDriverStore);

  const [numSteps, setNumSteps] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const [errorView, setErrorView] = useState(false);
  const [loadingView, setLoadingView] = useState(false);

  const [plans, setPlans] = useState();
  const [openVideo, setOpenVideo] = useState(true);
  const [currency, setCurrency] = useState(0);
  const [uberDiscount, setUberDiscount] = useState({});
  const [uberPro, setUberPro] = useState('Blue');

  const { data, loading, error } = useQuery(GET_CONFIG_PLANS.query, {
    variables: { country },
  });
  const [editDriverPlan] = useMutation(EDIT_DRIVER_PLAN.mutation, EDIT_DRIVER_PLAN.policies);
  const [createDriverPlan, { error: errorCreation }] = useMutation(
    CREATE_DRIVER_PLAN.mutation,
    CREATE_DRIVER_PLAN.policies,
  );

  const handleClick = () => {
    setVerifiedList({ ...verifiedList, plan: true });
    handleOnBoarding('Elegir plan de arriendo', uid, 'Completado');
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };
  const [edit, setEdit] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedMode, setSelectedMode] = useState('');

  const editPlan = (planId, mode, exchangeAddress = null) => {
    if (mode.includes('shared')) {
      setSelectedPlan({ ...selectedPlan, planId });
      setSelectedMode(mode);
    } else {
      setLoadingView(true);
      editDriverPlan({
        variables: {
          driverId: uid,
          planId,
          shiftId: mode,
          exchangeAddress,
          selectedShifts: Array(7).fill(mode),
        },
      })
        .then(() => {
          setEdit(false);
          setLoadingView(false);
        })
        .catch(() => {
          setErrorView(true);
        });
    }
  };

  const selectPlan = (planId, mode, exchangeAddress = null) => {
    if (mode.includes('shared')) {
      setSelectedPlan({ ...selectedPlan, planId });
      setSelectedMode(mode);
    } else {
      setLoadingView(true);

      createDriverPlan({
        variables: {
          driverId: uid,
          planId,
          shiftId: mode,
          exchangeAddress,
          selectedShifts: Array(7).fill(mode),
        },
      })
        .then(() => {
          handleClick();
        })
        .catch(() => {
          setErrorView(true);
        });
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [planList, setPlanList] = useState([]);
  useEffect(() => {
    if (data) {
      const allPlans = {};
      const activePlans = data.getConfigPlans.filter((elem) => elem.active);

      activePlans.forEach((elem) => {
        allPlans[elem._id] = { ...elem };
      });

      const auxCant = Object.entries(allPlans).map((elem) => {
        if (elem[1]?.name) {
          return 1;
        }
        return 0;
      });

      setPlans(allPlans);
      setUberDiscount(data.getConfigAccounting.uberDiscount);
      setCurrency(data.getConfigAccounting.currency.current);
      setNumSteps(auxCant.reduce((a, b) => a + b, 0));
    }
  }, [data]);

  useEffect(() => {
    if (plans) {
      const auxPlanList = Object.entries(plans).map((elem) => {
        if (elem[1]?.name) {
          return (
            <PlanItemFunction
              discount={uberDiscount[uberPro]}
              key={elem[0]}
              plan={elem[1]}
              mode={`${elem[1].electric ? 'electric' : 'combustion'}-${
                elem[1].shifts.length > 1 ? 'shared' : 'exclusive'
              }`}
              currency={currency}
              selectPlan={(planId, mode) => {
                if (edit) {
                  editPlan(planId, mode);
                } else {
                  selectPlan(planId, mode);
                }
              }}
            />
          );
        }
        return null;
      });

      auxPlanList.sort(planOrderFunction);
      setPlanList(auxPlanList.filter((a) => a));
    }
  }, [plans, uberPro, edit]);

  if (loading || (loadingView && !errorView)) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '20px',
          flex: 1,
        }}
      >
        <LoadingProgress allScreen={false} />
      </Box>
    );
  }

  if (error || errorView || errorCreation) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          flex: 1,
        }}
      >
        <OnBoardingTitle
          noLineal
          title="Ha ocurrido un error"
          subtitle="Vuelva a intentar más tarde ó contacte con soporte para notificarnos del problema."
        />
        <Divider
          sx={{
            width: '100%',
            color: '#EAEAEA',
          }}
        />
        <Box sx={{ width: '100%' }}>
          <ErrorView />
        </Box>
      </Box>
    );
  }

  if (verifiedList.plan && !edit) {
    if (labelSubscription) {
      return (
        <>
          <NewSubscriptionPlan onBoarding />
          <SubmitButton text="Cambiar plan" handler={() => setEdit(true)} />
        </>
      );
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <OldSubscriptionPlan onBoarding />
        </Box>
        <SubmitButton text="Cambiar plan" handler={() => setEdit(true)} />
      </Box>
    );
  }

  if (selectedMode.includes('shared')) {
    return (
      <SelectContentPlan
        mode={selectedMode}
        planId={plans[selectedPlan.planId]._id}
        selectPlan={edit ? editPlan : selectPlan}
        shifts={plans[selectedPlan.planId].shifts}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <VideoModal
        srcVideo="https://www.youtube.com/embed/OtNG_MWrO1o"
        title="¿Cómo se calcula el costo del plan?"
        open={openVideo}
        handleClose={() => {
          setOpenVideo(false);
        }}
      />

      <Box sx={{ width: '100%', paddingInline: '20px' }}>
        <Typography sx={{ fontFamily: 'Poppins-Medium', textAlign: 'start', marginBottom: '10px' }}>
          Elige tu plan de arriendo
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', color: '#696969' }}>
          Conoce los diferentes descuentos en tu plan de arriendo según tu categoría en Uber Pro.
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', color: '#696969' }}>
          Se te asignará un auto según disponibilidad y plan escogido.
        </Typography>
      </Box>

      <Box
        sx={{
          boxShadow: '0px 0px 15px #ccc',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Medium', color: azulTucar }}>
          Costo semanal =
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins-Medium', color: '#696969' }}>
          Costo base + variable x Km
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: '#F4F4F4',
          display: 'grid',
          borderRadius: '40px',
          gridTemplateColumns: 'repeat(4, 1fr)',
          width: '90%',
        }}
      >
        {['Blue', 'Gold', 'Platinum', 'Diamond'].map((uberProCategory) => {
          return (
            <Box
              key={uberProCategory}
              sx={{
                backgroundColor: uberPro === uberProCategory ? '#fff' : '#F4F4F4',
                borderRadius: '40px',
                margin: '3px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 0px',
              }}
              onClick={() => setUberPro(uberProCategory)}
            >
              <CategoryIcon category={uberProCategory} height="16px" />
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          overflowY: 'visible',
          maxWidth: '100vw',
          paddingInline: '10px',
          paddingBottom: '10px',
        }}
      >
        <SwipeableViews
          resistance
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {planList}
        </SwipeableViews>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <MobileStepper
          variant="dots"
          steps={numSteps}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
        />
      </Box>
    </Box>
  );
};

export default OnBoardingPlan;

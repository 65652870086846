import mixpanel from 'mixpanel-browser';

const handleRouteChange = (location) => {
  const dominio = window.location.host;
  const ruta = location.pathname;
  const fullPath = window.location.href;
  const referencia = document.referrer;
  const pageDate = document.lastModified;
  if (dominio === 'driver.tucar.app') {
    switch (ruta) {
      case '/signin':
        mixpanel.track(`P. Login`, {
          URL_Completa: fullPath,
          URL_Ruta: ruta,
          Referencia: referencia,
          Fecha: pageDate,
        });
        break;
      case '/signup':
        mixpanel.track(`P. Registro`, {
          URL_Completa: fullPath,
          URL_Ruta: ruta,
          Referencia: referencia,
          Fecha: pageDate,
        });
        break;
      case '/app/onboarding':
        mixpanel.track(`P. Onboarding`, {
          URL_Completa: fullPath,
          URL_Ruta: ruta,
          Referencia: referencia,
          Fecha: pageDate,
        });
        break;
      default:
      // No hacer nada
    }
  }
};

export default handleRouteChange;

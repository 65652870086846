import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Typography, Box } from '@mui/material';

import { GetUberStatusRedirect } from '../../../helpers/url';
import QueryParams from '../../../components/QueryParams';
import SIGN_IN_UBER from '../../../graphql/querys/auth/signInUber';
import { getAuthStore, logout } from '../../../graphql/store';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import getErrorMsgGraphql from '../../../helpers/getErrorMsgGraphql';
import SubmitButton from '../../../components/SubmitButton';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

function SignInUber() {
  const [msgError, setMsgError] = useState('');
  const query = QueryParams();
  const navigate = useNavigate();
  const { accessToken } = useReactiveVar(getAuthStore);
  const { loading, error } = useQuery(SIGN_IN_UBER.query, {
    ...SIGN_IN_UBER.policies,
    variables: {
      code: query.get('code'),
      redirectUrl: `${
        process.env.TUCAR_AUTH_SERVICE
      }/api/users/signin-uber/${GetUberStatusRedirect()}`,
    },
  });
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore) || { data: { uid: '' } };

  useEffect(() => {
    if (!loading && accessToken) {
      navigate('/app/', { replace: true });
    }
    if (!loading && error) {
      logout(uid);
      const msgErrorAux = getErrorMsgGraphql(error) || '';
      if (msgErrorAux.includes('no_partner_for_user')) {
        setMsgError('No existe una cuenta Tucar vinculada a esta cuenta de uber.');
      } else {
        setMsgError('Ha ocurrido un error al iniciar sesión con Uber.');
      }
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <LoadingProgress />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            paddingInline: '40px',
            gap: '20px',
          }}
        >
          <OnBoardingTitle title="Error al iniciar sesión" showLogo />
          <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px', textAlign: 'center' }}>
            {msgError}
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px', textAlign: 'center' }}>
            Te recomendamos que cierres sesión en Uber (Navegador y Aplicación) antes de volver a
            intentarlo.
          </Typography>
          <SubmitButton
            text="Volver a inicio"
            handler={() => {
              navigate('/signin', { replace: true });
            }}
          />
        </Box>
      )}
    </>
  );
}

export default SignInUber;

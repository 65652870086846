import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { getDriverStore, getAuthStore } from '../../../graphql/store';
import Payment from '../../general/payment';
import GuaranteeShow from './guaranteeShow';
import handleOnBoarding from '../../../config/mixPanel/handleOnBoarding';

const OnBoardingGuarantee = () => {
  const {
    verifiedList: { guarantee },
  } = useReactiveVar(getDriverStore);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  if (guarantee) {
    handleOnBoarding('Pagar garantía', uid, 'Completado');
    return <GuaranteeShow />;
  }
  return <Payment onBoarding />;
};

export default OnBoardingGuarantee;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, Box, Divider } from '@mui/material';

import ContactForm from '../../../../components/auth/SignUpForm';
import SIGN_UP_TUCAR from '../../../../graphql/mutations/auth/signUpTucar';
import { azulTucar, blancoTucar } from '../../../../themes/variants/TucarColors';
import tucarWhiteLogo from '../../../../../public/images/Logotipo_blanco.svg';
import ErrorModal from '../../../../components/ui/ErrorModal';
import BackButton from '../../../../components/BackButton';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import handleMixPanelClickButton from '../../../../config/mixPanel/handleClickButton';
import getErrorMsgGraphql from '../../../../helpers/getErrorMsgGraphql';

const SignUpTucar = ({ tucarSignUpState: { tucarSignUp, setTucarSignUp } }) => {
  const [open, setOpen] = useState('');
  const [errorTitle, setErrorTitle] = useState('Error de registro');
  const [errorForm, setErrorForm] = useState(false);

  const [signUpWithTucar, { loading, error }] = useMutation(
    SIGN_UP_TUCAR.mutation,
    SIGN_UP_TUCAR.policies,
  );
  const changeTucarSignUpState = () => {
    setTucarSignUp(!tucarSignUp);
  };

  const executeMutation = (variables) => {
    handleMixPanelClickButton('Aceptar y continuar', 'Signup - Registro');
    signUpWithTucar({
      variables,
    });
  };

  useEffect(() => {
    if (error) {
      const errorMsg = getErrorMsgGraphql(error) || '';
      if (errorMsg.includes('Already existant')) {
        setErrorTitle('Correo ya registrado');
        setOpen('Email ya registrado.');
      } else if (errorMsg.includes('Phone')) {
        setErrorTitle('Teléfono ya registrado');
        setOpen('Teléfono ya registrado.');
      } else {
        setErrorTitle('Error de registro');
        setOpen(
          'Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat.',
        );
      }
      setErrorForm(false);
    }
  }, [loading]);

  const renderComponent = tucarSignUp ? (
    <Box>
      {loading ? (
        <LoadingProgress allScreen={false} />
      ) : (
        <Box sx={{ padding: '0px 20px', width: '450px', maxWidth: '100vw' }}>
          <ContactForm
            executeMutation={executeMutation}
            error={errorForm}
            setError={setErrorForm}
          />
          <Box sx={{ marginTop: '-10px' }} display="flex" justifyContent="center" fullwidth>
            <BackButton text="Atrás" handler={changeTucarSignUpState} />
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <Box justifyContent="center">
      <Divider
        sx={{
          margin: '20px 0px 20px 0px',
          color: '#CCCCCC',
          width: '100%',
          fontFamily: 'Poppins-Regular',
        }}
      >
        o
      </Divider>
      <Button
        sx={{
          color: blancoTucar,
          backgroundColor: azulTucar,
          fontFamily: 'Poppins-Medium',
          fontSize: '14px',
          borderRadius: '27px',
          height: '40px',
          width: '100%',
          lineHeight: 1,
          '&:hover': {
            backgroundColor: '#0057B8CC',
          },
          justifyContent: 'space-evenly',
        }}
        onClick={() => {
          handleMixPanelClickButton('Regístrate con Tucar', 'Signup - Registro');
          const dataLayer = window.dataLayer || [];
          dataLayer.push({
            event: 'buttonClick',
            buttonName: 'registro',
          });
          changeTucarSignUpState();
        }}
      >
        <img src={tucarWhiteLogo} alt="tucarWhiteLogo" style={{ width: '100px', height: '23px' }} />
        <Box paddingTop="4px">Regístrate con Tucar</Box>
      </Button>
    </Box>
  );

  return (
    <>
      <ErrorModal
        open={!!open}
        onClose={() => {
          setOpen(false);
          setErrorForm(true);
        }}
        content={`${open}`}
        title={`${errorTitle}`}
      />
      {renderComponent}
    </>
  );
};

SignUpTucar.propTypes = {
  tucarSignUpState: PropTypes.shape({
    tucarSignUp: PropTypes.bool.isRequired,
    setTucarSignUp: PropTypes.func.isRequired,
  }).isRequired,
};

export default SignUpTucar;

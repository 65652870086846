import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import moment from 'moment';

import TopBar from '../../../components/ui/TopNav';
import { formatterCLP, formatterKm } from '../../../helpers/formatters';
import Tabs from '../../../components/ui/Tabs';
import LiquidationsBox from '../../app/liquidation/OldVersion/liquidationBox';
import AllSteps from './steps';
import { emptyStep } from '../helpers';
import TutorialModal from '../modal';
import dataQuery from './info';

function transformLiquidation(elem, stepObject) {
  const totalApps = elem.incomes.uber + elem.incomes.didi + elem.incomes.cabify + elem.incomes.beat;
  const totalExpenses = Object.values(elem.expenses).reduce((a, b) => {
    if (typeof b === 'number') {
      return a + b;
    }
    return a;
  }, 0);

  const kmWeek = elem.km.reduce((acc, curr) => acc + curr, 0);
  return {
    title: 'Liquidación',
    amount: Math.abs(elem.balance),
    positive: elem.balance > 0,
    comment: moment(elem.date).format('DD-MM-YYYY'),
    date: moment(elem.date).format('DD/MM/YYYY'),
    status: elem.settled ? 'Pagado' : 'Pendiente',
    incomes: [
      { right: 'Ingresos en Apps', left: formatterCLP.format(totalApps) },
      { right: 'Reembolsos', left: formatterCLP.format(elem.incomes.other) },
      { right: 'Pago por adelantado', left: formatterCLP.format(elem.prePay) },
      {
        right: 'Total',
        left: formatterCLP.format(totalApps + elem.incomes.other + elem.prePay),
      },
    ],
    expenses: [
      {
        right: 'Km recorridos',
        left: formatterKm.format(kmWeek),
        style: stepObject.styleElement?.row?.km,
      },
      {
        right: 'Costo del plan',
        left: formatterCLP.format(elem.expenses.rent),
        style: stepObject.styleElement?.row?.rent,
      },
      {
        right: 'TAG',
        left: formatterCLP.format(elem.expenses.tag),
        style: stepObject.styleElement?.row?.tag,
      },
      {
        right: 'Multas de tránsito',
        left: formatterCLP.format(elem.expenses.fines),
        style: stepObject.styleElement?.row?.fines,
      },
      {
        right: 'Penalizaciones',
        left: formatterCLP.format(elem.expenses.penaltys),
        popText:
          'Cargos emitidos por incumplimiento de los Términos de Servicio durante el arriendo. Pueden ser por combustible, suciedad, atrasos, inasistencia a la cita de mantención, pieza faltante al auto, entre otros.',
        style: stepObject.styleElement?.row?.penaltys,
      },
      {
        right: 'Garantía',
        left: formatterCLP.format(elem.expenses.guarantee),
        style: stepObject.styleElement?.row?.guarantee,
      },
      {
        right: 'Deudas',
        left: formatterCLP.format(elem.debt),
        style: stepObject.styleElement?.row?.debt,
      },
      {
        right: 'Otros cargos',
        left: formatterCLP.format(elem.expenses.other),
        popText: 'Ajustes en las liquidaciones y cobros de deducible, entre otros.',
        style: stepObject.styleElement?.row?.other,
      },
      {
        right: 'Cuotas',
        left: formatterCLP.format(elem.installment),
        popText: 'Cuotas pactadas de pago de garantía ó deducible',
        style: stepObject.styleElement?.row?.installment,
      },
      {
        right: 'Total',
        left: formatterCLP.format(totalExpenses + elem.installment),
        style: stepObject.styleElement?.row?.total,
      },
    ],
  };
}

const TutorialLiquidations = () => {
  const [handlePageChange] = useOutletContext();
  const [step, setStep] = useState(1);
  const [stepObject, setStepObject] = useState(emptyStep);
  const navigation = useNavigate();
  const tabs = [
    { value: 'pendient', label: 'Pendientes' },
    { value: 'paid', label: 'Pagadas' },
    { value: 'overdue', label: 'Vencidas' },
  ];
  const [content, setContent] = useState({});
  const [currentTab, setCurrentTab] = useState('pendient');
  const tabComponent = {
    pendient: (
      <LiquidationsBox
        key="pendient"
        content={content.pendient}
        styleElement={stepObject.styleElement}
        openModal
      />
    ),
    paid: (
      <LiquidationsBox key="paid" content={content.paid} styleElement={stepObject.styleElement} />
    ),
    overdue: (
      <LiquidationsBox
        key="overdue"
        content={content.overdue}
        styleElement={stepObject.styleElement}
      />
    ),
  };

  useEffect(() => {
    handlePageChange(<TopBar title="Liquidaciones" />, true);
  }, []);

  const handleNext = () => {
    stepObject.nextFunction(setStep, step, setCurrentTab);
  };

  const handlePrev = () => {
    if (stepObject.redirectPrev) {
      navigation(stepObject.redirectPrev, { replace: true });
    }
    stepObject.prevFunction(setStep, step, setCurrentTab);
  };

  useEffect(() => {
    if (Object.keys(AllSteps).some((key) => key === `${step}`)) {
      setStepObject(AllSteps[step]);
    }
  }, [step]);

  useEffect(() => {
    if (dataQuery) {
      const auxData = dataQuery.getDriverBillWeek;
      const auxContent = auxData.map((liquidation) => {
        return transformLiquidation(liquidation, stepObject);
      });

      const firstPendientIndex = auxContent.findIndex(
        (elem) => elem.status === 'Pendiente' && !elem.positive,
      );
      const auxPendientes =
        firstPendientIndex !== -1 ? auxContent.splice(firstPendientIndex, 1) : [];

      const auxVencidos = auxContent
        .filter((elem) => elem.status === 'Pendiente')
        .map((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.status = 'Vencida';
          return elem;
        });

      setContent({
        pendient: [...auxPendientes],
        paid: auxContent.filter((elem) => elem.status === 'Pagado'),
        overdue: auxVencidos.filter((elem) => elem.status === 'Vencida'),
      });
    }
  }, [dataQuery, stepObject]);

  return (
    <Box
      sx={{
        paddingTop: '10px',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        ...stepObject.styleElement?.container,
      }}
    >
      <TutorialModal
        exitLink="/app/liquidations"
        redirectPrev={stepObject?.redirectPrev}
        numSteps={Object.keys(AllSteps).length}
        activeStep={step}
        position={stepObject.ubication}
        next={handleNext}
        prev={handlePrev}
        open
        title={stepObject.title}
        content={stepObject.content}
      />

      <Tabs
        tabStyle={stepObject.styleElement?.firstTab}
        containerStyle={{
          ...stepObject.styleElement?.Tabs,
          height: 'max-content',
        }}
        currentTabName={currentTab}
        setCurrentTabFunction={setCurrentTab}
        tabs={tabs}
      />
      <Box sx={{ overflow: 'auto', height: '100%' }}>{tabComponent[currentTab]}</Box>
    </Box>
  );
};

export default TutorialLiquidations;

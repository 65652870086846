import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '@tucar/pit-stop';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import {
  getAuthStore,
  getRefreshToken,
  setLoading,
  isLoading,
  logout,
} from '../../../graphql/store';

import POST_LOGOUT from '../../../graphql/mutations/logOut';
import { azulTucar } from '../../../themes/variants/TucarColors';

const LogOut = ({ showIcon, noLinealOnboarding }) => {
  const [desactivateToken] = useMutation(POST_LOGOUT.mutation, POST_LOGOUT.policies);
  const [load, setLoad] = useState(false);
  const refreshToken = getRefreshToken();
  const navigate = useNavigate();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore) || { data: { uid: '' } };

  useEffect(() => {
    if (load) {
      setLoading(false);
      logout(uid);
      navigate('/signin', { replace: true });
      setLoad(false);
    } else if (!isLoading()) {
      setLoading(true);
    }
  }, [load, isLoading]);

  if (noLinealOnboarding) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        onClick={() => {
          desactivateToken({ variables: { refreshToken } });
          setLoad(true);
        }}
      >
        <FeatherIcon icon="log-out" stroke={azulTucar} />
      </Box>
    );
  }

  return (
    <Button
      fullWidth
      data-testid="logOutButton"
      variant="ghost"
      onClick={() => {
        desactivateToken({ variables: { refreshToken } });
        setLoad(true);
      }}
    >
      {showIcon ? <Icon name="exitToAppOutlined" /> : null}
      Cerrar sesión
    </Button>
  );
};

LogOut.propTypes = {
  showIcon: PropTypes.bool,
  noLinealOnboarding: PropTypes.bool,
};

LogOut.defaultProps = {
  showIcon: true,
  noLinealOnboarding: false,
};

export default LogOut;

import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { getAuthStore } from '../../../graphql/store';
import UPDATE_INFO_DRIVER from '../../../graphql/mutations/driver/updateInfoDriver';
import InfoForm from './InfoForm';
import ErrorModal from '../../../components/ui/ErrorModal';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';
import handleOnBoarding from '../../../config/mixPanel/handleOnBoarding';

const OnBoardingInfo = () => {
  const [open, setOpen] = useState(false);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [updateDriverInfo, { loading, data, error }] = useMutation(
    UPDATE_INFO_DRIVER.mutation,
    UPDATE_INFO_DRIVER.policies,
  );
  const executeMutation = (variables) => {
    updateDriverInfo({
      variables,
    });
    handleOnBoarding('Completar información personal', uid, 'Completado', variables.source);
  };

  useEffect(() => {
    if (!loading) {
      if (data) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      if (error) {
        setOpen(true);
      }
    }
  }, [loading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px',
      }}
    >
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat"
        title="Error al enviar información"
        emojiSymbol="🤔"
      />
      <Box
        sx={{
          paddingInline: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <OnBoardingTitle
          title="Información personal"
          subtitle="Terminemos con la información básica"
        />
        <Divider
          sx={{
            width: '100%',
            color: '#EAEAEA',
          }}
        />
      </Box>
      <InfoForm executeMutation={executeMutation} />
    </Box>
  );
};

export default OnBoardingInfo;

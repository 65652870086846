import { gql } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import insertDataLayer from '../../../helpers/GTM';
import { setAuth } from '../../store';

const SIGN_UP_UBER = {
  mutation: gql`
    mutation Mutation($code: String!, $redirectUrl: String!) {
      signUpUber(code: $code, redirectUrl: $redirectUrl) {
        accessToken
        refreshToken
        data {
          uid
          email
          firstname
          lastname
          userType
          verified
        }
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.signUpUber) {
        const { accessToken, refreshToken, data } = response.signUpUber;
        setAuth({ accessToken, refreshToken, data });
        insertDataLayer();

        const { uid: userID, email: userEmail, firstname, lastname } = data;
        mixpanel.people.set({
          UserID: userID,
          Nombre: `${firstname} ${lastname}`,
          Email: userEmail,
          Paso_Documentos: 'Pendiente',
          Paso_Garantia: 'Pendiente',
          Paso_Banco: 'Pendiente',
          Paso_Personal: 'Pendiente',
          Paso_Plan: 'Pendiente',
          Paso_Uber: 'Pendiente',
          Onboarding: 'Incompleto',
        });
        mixpanel.identify(userID);
        mixpanel.track('Registro Uber', {
          userId: userID || '',
          date: new Date(),
        });

        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: 'Registro Uber GTM',
          userId: data?.uid || '',
          date: new Date(),
        });
      }
    },

    errorPolicy: 'all',
  },
};

export default SIGN_UP_UBER;

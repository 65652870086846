import mixpanel from 'mixpanel-browser';

const handleOnBoarding = (step, uid, status, referenceInfo = '-') => {
  switch (step) {
    case 'Subir documentos y antecedentes':
      mixpanel.people.set({
        UserID: uid,
        Paso_Documentos: status,
        date: new Date(),
      });
      mixpanel.track('Act. Onboarding', {
        userId: uid,
        date: new Date(),
      });
      break;
    case 'Pagar garantía':
      mixpanel.people.set({
        UserID: uid,
        Paso_Garantia: status,
        date: new Date(),
      });
      mixpanel.track('Act. Onboarding', {
        userId: uid,
        date: new Date(),
      });
      break;
    case 'Agregar cuenta bancaria':
      mixpanel.people.set({
        UserID: uid,
        Paso_Banco: status,
        date: new Date(),
      });
      mixpanel.track('Act. Onboarding', {
        userId: uid,
        date: new Date(),
      });
      break;
    case 'Completar información personal':
      mixpanel.people.set({
        UserID: uid,
        Paso_Personal: status,
        date: new Date(),
        Referencia: referenceInfo,
      });
      mixpanel.track('Act. Onboarding', {
        userId: uid,
        date: new Date(),
      });
      break;
    case 'Elegir plan de arriendo':
      mixpanel.people.set({
        UserID: uid,
        Paso_Plan: status,
        date: new Date(),
      });
      mixpanel.track('Act. Onboarding', {
        userId: uid,
        date: new Date(),
      });
      break;
    case 'Vincular cuenta Uber':
      mixpanel.people.set({
        UserID: uid,
        Paso_Uber: status,
        date: new Date(),
      });
      mixpanel.track('Act. Onboarding', {
        userId: uid,
        date: new Date(),
      });
      break;
    default:
      break;
  }
};

export default handleOnBoarding;

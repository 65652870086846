import mixpanel from 'mixpanel-browser';

const handleMixPanelClickButton = (buttonName, buttonUbication) => {
  const fullPath = window.location.href;
  const pagePath = window.location.pathname;
  const pageDate = document.lastModified;

  mixpanel.track(`B. ${buttonName}`, {
    Ubicacion: buttonUbication,
    Nombre: buttonName,
    URL_Completa: fullPath,
    URL_Ruta: pagePath,
    Fecha: pageDate,
  });
};

export default handleMixPanelClickButton;

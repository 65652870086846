import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { logout, getAuthStore } from '../graphql/store';
import SubmitButton from './SubmitButton';

const ErrorFallback = ({ resetError }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore) || { data: { uid: '' } };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '9px',
        minHeight: '100vh',
        paddingInline: '20px',
      }}
    >
      <Box sx={{ width: '450px', maxWidth: '100vw' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 20px 20px 20px',
            paddingInline: '20px',
          }}
        >
          <Typography sx={{ marginTop: '35px', fontFamily: 'Poppins-Medium' }} variant="h2">
            Ha ocurrido un error inesperado
          </Typography>
          <Typography sx={{ marginTop: '35px', fontFamily: 'Poppins-Regular' }} variant="h3">
            Vuelva a intentar más tarde o llame a soporte para notificarnos del problema.
          </Typography>
        </Box>
        <Divider
          sx={{
            marginBottom: '20px',
            marginTop: '20px',
            borderBottomWidth: '1px',
            borderBottomColor: '#F9F9F9',
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <SubmitButton text="Soporte" handler={() => window.Intercom('show')} />
          <SubmitButton
            text="Salir"
            handler={() => {
              resetError();
              logout(uid);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default ErrorFallback;

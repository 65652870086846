import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Typography, Link, Divider } from '@mui/material';
import { Link as reactLink, useOutletContext } from 'react-router-dom';
import { GetUberStatusRedirect } from '../../../helpers/url';
import URL_SIGN_IN_UBER from '../../../graphql/querys/auth/urlSignInUber';
import SignInTucar from './tucar';
import SignInUber from './uber';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';
import handleMixPanelClickButton from '../../../config/mixPanel/handleClickButton';

const SignIn = () => {
  const [disableUber, setDisableUber] = useState(false);
  const { loading, error, data } = useQuery(URL_SIGN_IN_UBER.query, {
    variables: {
      redirectUrl: `${
        process.env.TUCAR_AUTH_SERVICE
      }/api/users/signin-uber/${GetUberStatusRedirect()}`,
    },
  });

  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange();
  }, []);

  useEffect(() => {
    if (error) {
      setDisableUber(true);
    }
  }, [loading]);
  if (loading) return <LoadingProgress />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        paddingInline: '20px',
        backgroundColor: '#FFFFFF',
      }}
    >
      <OnBoardingTitle title="Nos volvemos a ver" emojiSymbol="🤩" showLogo />

      <Box>
        <Divider
          sx={{
            width: '100%',
            color: '#EAEAEA',
            mb: '20px',
          }}
        />
        <SignInTucar />
        <Box sx={{ mt: '15px', textAlign: 'center' }}>
          <Link
            onClick={() => handleMixPanelClickButton('¿No tienes una cuenta?', 'Signin - Login')}
            color="#000000"
            component={reactLink}
            data-testid="signUpLink"
            to="/signup"
          >
            <Typography
              sx={{ fontSize: '14px', fontFamily: 'Poppins-Light', color: '#333333' }}
              variant="text"
              type="link"
            >
              ¿No tienes una cuenta?
            </Typography>
          </Link>
        </Box>
        <Divider
          sx={{
            margin: '20px 0px 20px 0px',
            color: '#CCCCCC',
            fontFamily: 'Poppins-Regular',
          }}
        >
          o
        </Divider>
        <SignInUber disabled={disableUber} urlSignInUber={data?.urlSingInUber} />
      </Box>
    </Box>
  );
};

export default SignIn;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import REFRESH_TOKEN from '../graphql/querys/auth/refreshToken';
import LoadingScreen from '../components/LoadingScreen';
import {
  getAuthStore,
  getRefreshToken,
  getAuthProvider,
  setLoading,
  logout,
} from '../graphql/store';

const AuthContext = ({ children }) => {
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [refreshTokenQuery, { loading, error }] = useLazyQuery(
    REFRESH_TOKEN.query,
    REFRESH_TOKEN.policies,
  );
  const refreshToken = getRefreshToken();
  const authProvider = getAuthProvider();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore) || { data: { uid: '' } };

  useEffect(() => {
    if (!loading) {
      setLoadingAuth(false);
      if (error) {
        logout(uid);
      }
    } else {
      setLoadingAuth(true);
    }
  }, [loading]);

  useEffect(() => {
    if (refreshToken) {
      setLoading(true);
      refreshTokenQuery({ variables: { refreshToken, authProvider } });
    }
  }, []);
  return <>{loadingAuth ? <LoadingScreen /> : children}</>;
};

AuthContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContext;

import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

import QueryParams from '../../../components/QueryParams';
import ActionDecider from './ActionDecider';
import LinkUberEmail from './LinkUberEmail';
import UberHeroModal from './UberHeroContact';

import OnBoardingTitle from '../../../components/extras/OnboardingTitle';
import { getDriverStore, getAuthStore } from '../../../graphql/store';
import translateUberPro from '../../../helpers/translateUberPro';
import UberProGoals from '../../app/benefits/UberProGoals';
import CategoryIcon from '../../../components/ui/categoryIcon';
import handleOnBoarding from '../../../config/mixPanel/handleOnBoarding';

const OnBoardingAppsComponents = [
  {
    stage: 'decide',
    Comp: ActionDecider,
    OnBoardComp: (
      <>
        <OnBoardingTitle
          emojiSymbol="🚘📲"
          title="Vincula tu cuenta de Uber"
          subtitle="Si no tienes cuenta, primero deberás crear una y te reembolsaremos cualquier bonificación que recibamos (hasta $240.000)"
        />
        <Divider
          sx={{
            width: '100%',
            color: '#EAEAEA',
          }}
        />
      </>
    ),
  },
  {
    stage: 'link',
    Comp: LinkUberEmail,
    OnBoardComp: null,
  },
];

const OnBoardingApps = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const query = QueryParams();
  const [stage, changeStage] = useState('decide');
  const [openModal, setOpenModal] = useState(false);
  const { verifiedList, category } = useReactiveVar(getDriverStore);

  if (verifiedList?.app) {
    handleOnBoarding('Vincular cuenta Uber', uid, 'Compeltado');
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: '10px',
        }}
      >
        <OnBoardingTitle noLineal title="Tu cuenta de Uber a sido vinculada con éxito." />
        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <CategoryIcon category={category?.uberPro} />
          <Typography sx={{ lineHeight: 1, fontSize: '16px' }} fontFamily="Poppins-Medium">
            Categoría {translateUberPro(category?.uberPro)}
          </Typography>
        </Box>
        <Box>
          <UberProGoals />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <UberHeroModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
      />

      {OnBoardingAppsComponents.map(({ stage: stageComp, Comp, OnBoardComp }) =>
        stageComp === stage ? (
          <Box
            key={stageComp}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            {OnBoardComp}

            <Comp changeStage={changeStage} code={query.get('code')} setOpenModal={setOpenModal} />
          </Box>
        ) : null,
      )}
    </Box>
  );
};

export default OnBoardingApps;

import React from 'react';
import PropTypes from 'prop-types';
import UberButton from '../../../../components/ui/UberButton';
import handleMixPanelClickButton from '../../../../config/mixPanel/handleClickButton';

const SignInUber = ({ disabled, urlSignInUber }) => {
  return (
    <UberButton
      disabled={disabled}
      onClick={() => handleMixPanelClickButton('Ingresar con Uber', 'Signin - Login')}
      href={urlSignInUber}
      content="Ingresar con Uber"
    />
  );
};

SignInUber.propTypes = {
  disabled: PropTypes.bool,
  urlSignInUber: PropTypes.string,
};

SignInUber.defaultProps = {
  disabled: false,
  urlSignInUber: '',
};

export default SignInUber;
